const policyListListFormatter = (data, datakey) => {
  const returnData = {};
  const SAIBA_INSURERS = {
    "SBI General Insurance Company Limited": "SBI",
    "The New India Assurance Company Limited": "NEW_INDIA",
    "ICICI Lombard General Insurance Company Limited": "ICICI_LOMBARD",
    "HDFC ERGO General Insurance Company Limited": "HDFC_ERGO",
    "Go Digit General Insurance Limited": "DIGIT",
    "The Oriental Insurance Company Limited": "ORIENTAL",
    "United India Insurance Company Limited": "UNITED",
    "National Insurance Company Limited": "NATIONAL",
    "Reliance General Insurance Company Limited": "RELIANCE",
    "Royal Sundaram General Insurance Company Limited": "ROYAL_SUNDARAM",
    "IFFCO Tokio General Insurance Company Limited": "IFFCO_TOKIO",
    "Liberty Videocon General Insurance Company Limited": "LIBERTY",
    "Universal Sompo General Insurance Company Limited": "UNIVERSAL_SOMPO",
    "Bajaj Allianz General Insurance Company Limited": "BAJAJ_ALLIANZ",
    "Not Available": "NOT_AVAILABLE",
  };
  returnData[datakey] = {
    SBI: { total: 0, premium: 0 },
    HDFC_ERGO: { total: 0, premium: 0 },
    ICICI_LOMBARD: { total: 0, premium: 0 },
    NEW_INDIA: { total: 0, premium: 0 },
    DIGIT: { total: 0, premium: 0 },
    RELIANCE: { total: 0, premium: 0 },
    UNITED: { total: 0, premium: 0 },
  };
  const dataSet = data.data || data;
  dataSet.forEach((element) => {
    returnData[datakey][SAIBA_INSURERS[element["insurer_name"]]]["total"] =
      element["count"];
    returnData[datakey][SAIBA_INSURERS[element["insurer_name"]]]["premium"] =
      parseInt(element["sum"]);
  });
  Object.keys(returnData[datakey]).forEach((ele) => {
    returnData[datakey][ele][
      "total"
    ] = `${returnData[datakey][ele]["total"]} Policies`;
    returnData[datakey][ele]["premium"] = `₹ ${convertToString(
      parseInt(returnData[datakey][ele]["premium"])
    )} Premium`;
  });

  return returnData;
};

const convertToString = (val) => {
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + " Lac";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(0) + " k";
  }
  return val;
};
export default policyListListFormatter;
