import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const clientListSlice = createApi({
    reducerPath: "clientList",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://pbp.policy-parser.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        clientList: builder.query({
            query: (data) => ({ url: "/api/v1/client/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useClientListQuery } = clientListSlice;
