// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useClientListQuery } from "../store/clientListSlice";
import { default as policyListListVerifiedFormatter } from "../transformations/policyListListVerfiedFormatter";

import { usePolicyListVerifiedQuery } from "../store/policyListVerifiedSlice";

import { usePolicyListUnverifiedQuery } from "../store/policyListUnverifiedSlice";

function Dashboard() {
    const clientListData = useSelector((states) => states?.appStore?.clientListData);
    const policyListVerifiedData = useSelector((states) => states?.appStore?.policyListVerifiedData);
    const policyListUnverifiedData = useSelector((states) => states?.appStore?.policyListUnverifiedData);
    const [clientListSkip, setclientListSkip] = useState(true);
    const [clientListParams, setclientListParams] = useState({});
    const clientListQuery = useClientListQuery(clientListParams, { skip: clientListSkip });
    const [policyListVerifiedSkip, setpolicyListVerifiedSkip] = useState(true);
    const [policyListVerifiedParams, setpolicyListVerifiedParams] = useState({});
    const policyListVerifiedQuery = usePolicyListVerifiedQuery(policyListVerifiedParams, {
        skip: policyListVerifiedSkip,
    });
    const [policyListUnverifiedSkip, setpolicyListUnverifiedSkip] = useState(true);
    const [policyListUnverifiedParams, setpolicyListUnverifiedParams] = useState({});
    const policyListUnverifiedQuery = usePolicyListUnverifiedQuery(policyListUnverifiedParams, {
        skip: policyListUnverifiedSkip,
    });
    const dispatch = useDispatch();

    const policyListListUnverifiedData = (data) => {
        var formatedData = {
            // policyListUnverifiedData: data?.data || data,
            ...policyListListVerifiedFormatter(data?.data || data, "policyListUnverifiedData"),
        };
        dispatch(setStore(formatedData));
    };

    const policyListUnverifiedSuccess = (data) => {
        policyListListUnverifiedData(data);
    };
    useEffect(() => {
        if (policyListUnverifiedQuery.isSuccess) {
            policyListUnverifiedSuccess(policyListUnverifiedQuery);
        } else if (policyListUnverifiedQuery.isError) {
            policyListUnverifiedSuccess(null);
        }
    }, [policyListUnverifiedQuery]);
    const policyListUnverified = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListUnverifiedParams({
            size: data ? data["99"] : "99",
            page: data ? data["1"] : "1",
            order_by: data ? data["-updated_at"] : "-updated_at",
            // is_verified: data ? data["false"] : "false",
        });
        setpolicyListUnverifiedSkip(false);
    };

    const policyListListVerifiedData = (data) => {
        var formatedData = {
            ...policyListListVerifiedFormatter(data?.data || data, "policyListVerifiedData"),
        };
        dispatch(setStore(formatedData));
    };

    const policyListVerifiedSuccess = (data) => {
        policyListListVerifiedData(data);
    };
    useEffect(() => {
        if (policyListVerifiedQuery.isSuccess) {
            policyListVerifiedSuccess(policyListVerifiedQuery);
        } else if (policyListVerifiedQuery.isError) {
            policyListVerifiedSuccess(null);
        }
    }, [policyListVerifiedQuery]);
    const policyListVerified = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListVerifiedParams({
            size: data ? data["99"] : "99",
            page: data ? data["1"] : "1",
            order_by: data ? data["-updated_at"] : "-updated_at",
            // is_verified: data ? data["true"] : "true",
        });
        setpolicyListVerifiedSkip(false);
    };

    const clientListListData = (data) => {
        var formatedData = {
            clientListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const clientListSuccess = (data) => {
        clientListListData(data);
    };
    useEffect(() => {
        if (clientListQuery.isSuccess) {
            clientListSuccess(clientListQuery);
        } else if (clientListQuery.isError) {
            clientListSuccess(null);
        }
    }, [clientListQuery]);
    const clientList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setclientListParams({});
        setclientListSkip(false);
    };

    useEffect(() => {
        clientList();
        policyListVerified();
        policyListUnverified();
    }, []);
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Dashboard" }}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Clients List"
                        showSerialNumber={false}
                        xs={12}
                        rows={clientListData?.items || []}
                        columns={[
                            { field: "created_at", headerName: "Created on", type: "date" },
                            { field: "api_key", headerName: "API KEY" },
                            { field: "credits", headerName: "Available Credits", highlight: true },
                        ]}
                    ></TableWidget>

                    <TableWidget
                        header="Verified Policies"
                        showSerialNumber={false}
                        xs={12}
                        rows={policyListVerifiedData?.verified || []}
                        columns={[
                            { field: "requested_at", headerName: "Requested At",  type: "datetime"},
                            { field: "client", headerName: "Client" },
                            { field: "client_policy_identifier", headerName: "Client Policy Identifier" },
                            { field: "insurer_name", headerName: "Insurer", highlight: true },
                            { field: "policy_number", headerName: "Policy No" },
                            { headerName: "View Policy", type: "edit", url:"policies" },
                        ]}
                    ></TableWidget>

                    <TableWidget
                        header="Unverified Policies"
                        showSerialNumber={false}
                        xs={12}
                        rows={policyListVerifiedData?.unverified || []}
                        columns={[
                            { field: "created_at", headerName: "Extracted Date", type: "date" },
                            { field: "policy_number", headerName: "Policy No" },
                            { field: "insurer_name", headerName: "Insurer", highlight: true },
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "client", headerName: "Client" },
                            { field: "client_policy_identifier", headerName: "Client Policy Identifier" },
                            { field: "requested_at", headerName: "Requested At",  type: "datetime"},
                            { headerName: "Edit Policy", type: "edit", url: "policies" },
                        ]}
                    ></TableWidget>
                    <TableWidget
                        header="Unsuccessfull Request"


                        
                        showSerialNumber={false}
                        xs={12}
                        rows={policyListVerifiedData?.error || []}
                        columns={[
                            { field: "client", headerName: "Client" },
                            { field: "client_policy_identifier", headerName: "Client Policy Identifier" },
                            { field: "requested_at", headerName: "Requested At",  type: "datetime"},
                            { field: "reason", headerName: "Reason", },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Dashboard;
