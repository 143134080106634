// @ts-nocheck
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./authComponent/AuthenticatedRoute";
import UnAuthenticatedRoute from "./authComponent/UnAuthenticatedRoute";
import { mergeStore } from "./store/appSlice";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import {
    Login,
    Home,
    UploadPdf,
    Dashboard,
    ViewPolicies,
    ViewHistory,
    EditPolicy,
    DownloadReports,
    ProductReports,
} from "./pages";

function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userIdStore = useSelector((states) => states?.appStore?.userId);
    const userNameStore = useSelector((states) => states?.appStore?.username);
    const emailStore = useSelector((states) => states?.appStore?.email);
    const phoneStore = useSelector((states) => states?.appStore?.phone);

    if (userIdStore && !userId) {
        localStorage.setItem("userId", userIdStore);
        localStorage.setItem("userName", userNameStore || "");
        localStorage.setItem("email", emailStore || "");
        localStorage.setItem("phone", phoneStore || "");
    } else if (!userIdStore && userId) {
        var formatedData = {
            userId: userId,
            userName: userName || "",
            email: email || "",
            phone: phone || "",
        };
        dispatch(mergeStore(formatedData));
    }

    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };
        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;
    if (authToken) {
        var decoded = jwt_decode(authToken);
        if (Date.now() > decoded.exp * 1000) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Routes>
                        <Route path="/login" element={<Login />}></Route>

                        <Route
                            path="/"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Home />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/upload-pdf"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <UploadPdf />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/dashboard"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Dashboard />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/policies"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ViewPolicies />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/policies/history"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ViewHistory />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/policies/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <EditPolicy />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/reports"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <DownloadReports />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/product-reports"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ProductReports />
                                </AuthenticatedRoute>
                            }
                        ></Route>
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
