import Moment from "moment";

const countProcessedFormatter = (data, datakey) => {
  const returnData = {};
  returnData[datakey] = [];
  let temData = {};
  const dataSet = data.data || data;
  returnData["overAll"] = {
    uploaded: dataSet["total"],
    success: 0,
    error: 0,
  };
  dataSet["items"].forEach((element, index) => {
    let date = Moment(element["processed_at"]).format("yyyy-MM-DD");
    if (temData[date]) {
      temData[date] = {
        id: index,
        date: date,
        uploaded: temData[date]["uploaded"] + 1,
        success:
          element["status"] == "SUCCESS"
            ? temData[date]["success"] + 1
            : temData[date]["success"],
        error:
          element["status"] == "ERROR"
            ? temData[date]["error"] + 1
            : temData[date]["error"],
      };
    } else {
      temData[date] = {
        id: index,
        date: date,
        uploaded: 1,
        success: element["status"] == "SUCCESS" ? 1 : 0,
        error: element["status"] == "ERROR" ? 1 : 0,
      };
    }
    if (element["status"] == "SUCCESS") {
      returnData["overAll"]["success"] = returnData["overAll"]["success"] + 1;
    } else {
      returnData["overAll"]["error"] = returnData["overAll"]["error"] + 1;
    }
  });
  Object.keys(temData).forEach((ele, index) => {
    temData[ele]["id"] = index;
    temData[ele]["success_per"] = temData[ele]["uploaded"]
      ? `${(temData[ele]["uploaded"] * 100) / temData[ele]["uploaded"]} %`
      : `0 %`;
    returnData[datakey].push(temData[ele]);
  });
  returnData["overAll"]["success_per"] = returnData["overAll"]["uploaded"]
    ? `${
        (returnData["overAll"]["success"] * 100) /
        returnData["overAll"]["uploaded"]
      } %`
    : `0 %`;
  return returnData;
};
export default countProcessedFormatter;
