// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { usePolicyListQuery } from "../store/policyListSlice";

function ViewPolicies() {
    const policyListData = useSelector((states) => states?.appStore?.policyListData);
    const [policyListSkip, setpolicyListSkip] = useState(true);
    const [policyListParams, setpolicyListParams] = useState({});
    const policyListQuery = usePolicyListQuery(policyListParams, { skip: policyListSkip });
    const dispatch = useDispatch();

    const policyListListData = (data) => {
        var formatedData = {
            policyListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const policyListSuccess = (data) => {
        policyListListData(data);
    };
    useEffect(() => {
        if (policyListQuery.isSuccess) {
            policyListSuccess(policyListQuery);
        } else if (policyListQuery.isError) {
            policyListSuccess(null);
        }
    }, [policyListQuery]);
    const policyList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListParams({ order_by: data ? data["-created_at"] : "-created_at" });
        setpolicyListSkip(false);
    };

    useEffect(() => {
        policyList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "View Policies" }}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Extracted Policies"
                        showSerialNumber={false}
                        xs={12}
                        rows={policyListData?.items || []}
                        columns={[
                            { field: "created_at", headerName: "Extracted Date", type: "date" },
                            { field: "policy_number", headerName: "Policy No" },
                            { field: "insurer_name", headerName: "Insurer", highlight: true },
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "policy_type", headerName: "Policy Type" },
                            { field: "net_premium", headerName: "Net Premium", type: "currency" },
                            { field: "gross_premium", headerName: "Total Premium", type: "currency", highlight: true },
                            { field: "sum_insured", headerName: "Sum Insured", type: "currency" },
                            { field: "policy_start_date", headerName: "Start Date", type: "date" },
                            { field: "policy_end_date", headerName: "End Date", type: "date" },
                            { field: "policy_issue_date", headerName: "Policy Issue Date", type: "date" },
                            { headerName: "Edit Policy", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ViewPolicies;
