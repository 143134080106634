// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMButton, FormWidget, MetricWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useCountSuccessProcessedQuery } from "../store/countSuccessProcessedSlice";

import { usePolicyListQuery } from "../store/policyListSlice";

import { useCountSuccessDateQuery } from "../store/countSuccessDateSlice";

import { usePolicyListDateQuery } from "../store/policyListDateSlice";
import { default as countSuccessProcessedFormatter } from "../transformations/countSuccessProcessedFormatter";
import { default as countProcessedFormatter } from "../transformations/countProcessedFormatter";
function DownloadReports() {
    const overAll = useSelector((states) => states?.appStore?.overAll);
    const tableDataSucc = useSelector((states) => states?.appStore?.tableDataSucc);
    const [countSuccessProcessedSkip, setcountSuccessProcessedSkip] = useState(true);
    const [countSuccessProcessedParams, setcountSuccessProcessedParams] = useState({});
    const countSuccessProcessedQuery = useCountSuccessProcessedQuery(countSuccessProcessedParams, {
        skip: countSuccessProcessedSkip,
    });
    const [policyListSkip, setpolicyListSkip] = useState(true);
    const [policyListParams, setpolicyListParams] = useState({});
    const policyListQuery = usePolicyListQuery(policyListParams, { skip: policyListSkip });
    const [countSuccessDateSkip, setcountSuccessDateSkip] = useState(true);
    const [countSuccessDateParams, setcountSuccessDateParams] = useState({});
    const countSuccessDateQuery = useCountSuccessDateQuery(countSuccessDateParams, { skip: countSuccessDateSkip });
    const [policyListDateSkip, setpolicyListDateSkip] = useState(true);
    const [policyListDateParams, setpolicyListDateParams] = useState({});
    const policyListDateQuery = usePolicyListDateQuery(policyListDateParams, { skip: policyListDateSkip });
    const dispatch = useDispatch();
    const [filterState, setfilterState] = useState(false);

    const policyListDateListData = (data) => {
        var formatedData = {
            policyListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const policyListDateSuccess = (data) => {
        policyListDateListData(data);
    };
    useEffect(() => {
        if (policyListDateQuery.isSuccess) {
            policyListDateSuccess(policyListDateQuery);
        } else if (policyListDateQuery.isError) {
            policyListDateSuccess(null);
        }
    }, [policyListDateQuery]);
    const policyListDate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListDateParams({
            created_at__gte: data ? data["start_date"] : "start_date",
            created_at__lte: data ? data["end_date"] : "end_date",
        });
        setpolicyListDateSkip(false);
    };

    const countSuccessDateListData = (data) => {
        var formatedData = {
            ...countProcessedFormatter(data?.data || data, "tableDataSucc"),
        };
        dispatch(setStore(formatedData));
    };

    const countSuccessDateSuccess = (data) => {
        countSuccessDateListData(data);
    };
    useEffect(() => {
        if (countSuccessDateQuery.isSuccess) {
            countSuccessDateSuccess(countSuccessDateQuery);
        } else if (countSuccessDateQuery.isError) {
            countSuccessDateSuccess(null);
        }
    }, [countSuccessDateQuery]);
    const countSuccessDate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountSuccessDateParams({
            created_at__gte: data ? data["start_date"] : "start_date",
            created_at__lte: data ? data["end_date"] : "end_date",
        });
        setcountSuccessDateSkip(false);
    };

    const policyUpdateApi = (data) => {
        countSuccessDate(data);

        policyListDate(data);
    };

    const setFilterState = () => {
        setfilterState(!filterState);
    };

    const filterClick = (data) => {
        setFilterState(data);
    };

    const policyListListData = (data) => {
        var formatedData = {
            policyListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const policyListSuccess = (data) => {
        policyListListData(data);
    };
    useEffect(() => {
        if (policyListQuery.isSuccess) {
            policyListSuccess(policyListQuery);
        } else if (policyListQuery.isError) {
            policyListSuccess(null);
        }
    }, [policyListQuery]);
    const policyList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListSkip(false);
    };

    const countSuccessProcessedListData = (data) => {
        var formatedData = {
            ...countSuccessProcessedFormatter(data?.data || data, "tableDataSucc"),
        };
        dispatch(setStore(formatedData));
    };

    const countSuccessProcessedSuccess = (data) => {
        countSuccessProcessedListData(data);
    };
    useEffect(() => {
        if (countSuccessProcessedQuery.isSuccess) {
            countSuccessProcessedSuccess(countSuccessProcessedQuery);
        } else if (countSuccessProcessedQuery.isError) {
            countSuccessProcessedSuccess(null);
        }
    }, [countSuccessProcessedQuery]);
    const countSuccessProcessed = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountSuccessProcessedParams({ size: data ? data["99"] : "99", page: data ? data["1"] : "1" });
        setcountSuccessProcessedSkip(false);
    };

    useEffect(() => {
        countSuccessProcessed();
        policyList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Extraction Reports" }}>
                <PMGrid container={true} justifyContent="center" marginTop={2}>
                    <PMGrid container={true} justifyContent="right">
                        <PMButton variant="contained" label="Select Date Range" onClick={filterClick}></PMButton>
                    </PMGrid>
                    {filterState ? (
                        <FormWidget
                            submitButtonLabel="Apply Filter"
                            Onsubmit={policyUpdateApi}
                            fieldsets={[
                                {
                                    fields: [
                                        { label: "Start Date", name: "start_date", type: "date", required: true },
                                        { label: "End Date", name: "end_date", type: "date", required: true },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Total PDF"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={overAll?.uploaded || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Success"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={overAll?.success || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Errors"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={overAll?.error || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Success %"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={overAll?.success_per || []}
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} sx={{ my: 2 }}>
                    <TableWidget
                        header="Extraction Summary Report"
                        showExportOptions={true}
                        showSerialNumber={true}
                        showQuickFilter={false}
                        xs={12}
                        rows={tableDataSucc || []}
                        columns={[
                            { field: "date", headerName: "Date", type: "date" },
                            { field: "uploaded", headerName: "Total PDFs Uploaded" },
                            { field: "success", headerName: "Successfully Parsed", highlight: true },
                            { field: "error", headerName: "Errors" },
                            { field: "success_per", headerName: "Success%" },
                            { field: "user", headerName: "User Name" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default DownloadReports;
