import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const verifyPolicySlice = createApi({
  reducerPath: "policyUpdate",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://pbp.policy-parser.demo.permute.in",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", "Bearer " + token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    verifyPolicy: builder.mutation({
      query: ({ id, data }) => ({
        url: "/api/v1/policy/verify?id=" + id,
        method: "post",
        body: data,
      }),
    }),
  }),
});

export const { useVerifyPolicyMutation } = verifyPolicySlice;
