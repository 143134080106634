// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { DetailWidget, FormWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePolicyDetailsQuery } from "../store/policyDetailsSlice";

import { usePolicyUpdateMutation } from "../store/policyUpdateSlice";
import { useVerifyPolicyMutation } from "../store/verifyPolicySlice";
import { default as policyDetailVerifiedFormatter } from "../transformations/policyDetailVerfiedFormatter";

function EditPolicy() {
    const policyDetailsData = useSelector((states) => states?.appStore?.policyDetailsData);
    const [policyDetailsSkip, setpolicyDetailsSkip] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [policyDetailsParams, setpolicyDetailsParams] = useState({});
    const policyDetailsQuery = usePolicyDetailsQuery(policyDetailsParams, { skip: policyDetailsSkip });
    const policyUpdateMutation = usePolicyUpdateMutation();
    const verifyPolicyMutation = useVerifyPolicyMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const pocreated = (data) => {
        history("/policies/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Updated", { variant: "success" });
    };
    const onVerified = async (data) => {
        setIsLoading(true)
        console.log(data)
        if (policyDetailsData) {
            await verifyPolicyMutation[0]({ id: policyDetailsData["id"], data: {  } }).unwrap();
        }
    }

    const verifyPolicySuscess = (data) => {
        showSuccessAlert(data);
        pocreated()
    };

    useEffect(() => {
        if (verifyPolicyMutation[1].isSuccess) {
            verifyPolicySuscess(verifyPolicyMutation[1]);
        }
        if (verifyPolicyMutation[1].isError) {
            enqueueSnackbar(errorSerializer(verifyPolicyMutation[1]), { variant: "error" });
        }
    }, [verifyPolicyMutation]);

    const policyUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            await policyUpdateMutation[0]({ id: apiData["id"], data: { ...apiData } }).unwrap();
        }
    };

    const watchPolicyFormChange = (data) => {
            
        const formatedData = {
            ...policyDetailVerifiedFormatter(data?.data || data, "policyDetailsData"),
        };
        dispatch(setStore(formatedData));
    };

    const policyUpdateSuscess = (data) => {
        setIsLoading(true)
        // showSuccessAlert(data);
        onVerified()
        // pocreated(data);
    };
    useEffect(() => {
        if (policyUpdateMutation[1].isSuccess) {
            policyUpdateSuscess(policyUpdateMutation[1]);
        }
        if (policyUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(policyUpdateMutation[1]), { variant: "error" });
        }
    }, [policyUpdateMutation]);

    const policyUpdateApi = (data) => {
        policyUpdate(data);
    };

    const savepolicyDetailsData = (data) => {
        var formatedData = {
            ...policyDetailVerifiedFormatter(data?.data || data, "policyDetailsData"),
        };
        dispatch(setStore(formatedData));
    };

    const policyDetailsSuccess = (data) => {
        savepolicyDetailsData(data);
    };
    useEffect(() => {
        if (policyDetailsQuery.isSuccess) {
            policyDetailsSuccess(policyDetailsQuery);
        } else if (policyDetailsQuery.isError) {
            policyDetailsSuccess(null);
        }
    }, [policyDetailsQuery]);
    const policyDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyDetailsParams({ id: id });
        setpolicyDetailsSkip(false);
    };

    useEffect(() => {
        policyDetails();
    }, [id]);
    var pagetitle = ""
    if (policyDetailsData?.is_verified){
        pagetitle = "View Policy"
    }
    else{
        pagetitle = "Edit Policy"
    }
    console.log(policyDetailsData?.policy_type,"====================")
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title:  pagetitle}}>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Policy"
                        isLoading={policyUpdateMutation[1].isLoading}
                        disabled={policyDetailsData?.is_verified}
                        defaultValues={policyDetailsData || []}
                        Onsubmit={policyUpdateApi}
                        isDisabled={policyDetailsData?.is_verified}
                        Onchange={watchPolicyFormChange}
                        submitButtonLabel="Update and verified"
                        fieldsets={[
                            {
                                direction: "row",
                                header: "Customer Details",
                                grid: 3,
                                fields: [
                                    { label: "Customer Name", name: "customer_name", type: (policyDetailsData?.is_verified?"display":"text") , "required":true},
                                    { label: "Mobile No", name: "customer_mobile_number", type: (policyDetailsData?.is_verified?"display":"text")},
                                    { label: "Address", name: "customer_address", type: (policyDetailsData?.is_verified?"display":"text") },
                                    // { label: "Address", name: "address", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "City", name: "customer_city", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "State", name: "customer_state", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Customer Group", name: "customer_group", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Vertical Type", name: "vertical_type", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Business Type", name: "business_type", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Org Type", name: "org_type", type: (policyDetailsData?.is_verified?"display":"text") },
                                ],
                            },
                            {
                                direction: "row",
                                grid: 3,
                                header: "Vehicle Details",
                                fields: [
                                    { label: "Registration No", name: "vehicle_registration_number", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Make", name: "vehicle_make", type: (policyDetailsData?.is_verified?"display":"text"), "required":true },
                                    { label: "Model", name: "vehicle_model", type: (policyDetailsData?.is_verified?"display":"text") , "required":true},
                                    { label: "Variant", name: "vehicle_variant", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "No of Wheels", name: "number_of_wheels", type: (policyDetailsData?.is_verified?"display":"text"),
                                    validation: { min: 2, max: 4, pattern: "^\\d?$" }, },
                                    { label: "Sum Insured", name: "sum_insured", type: (policyDetailsData?.is_verified?"display":"text"),
                                    validation: { min: (policyDetailsData?.policy_type=="Third Party"?0:1),max:(policyDetailsData?.policy_type=="Third Party"?0:99999999999999999), pattern: "^\\d?$" }, },
                                    { label: "Manufacturing Date", name: "date_of_manufacture", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Ncb", name: "no_claim_bonus", type: (policyDetailsData?.is_verified?"display":"text"), 
                                    validation: { min: 0, max: 50, pattern: "^\\d+(?:\\.\\d+)?$" }, },
                                    {
                                        label: "Vehicle Registration Status",
                                        name: "vehicle_registrasion_status",
                                        type: (policyDetailsData?.is_verified?"display":"text"),
                                    },
                                    {
                                        label: "RTO City",
                                        name: "rto_city",
                                        type: (policyDetailsData?.is_verified?"display":"text"),
                                        required: true
                                    },
                                    {
                                        label: "RTO State",
                                        name: "rto_state",
                                        type: (policyDetailsData?.is_verified?"display":"text"),
                                        required: true
                                    },
                                    {
                                        label: "RTO Code",
                                        name: "rto_code",
                                        type: (policyDetailsData?.is_verified?"display":"text"),
                                        required: true
                                    },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Premium Details",
                                grid: 3,
                                fields: [
                                    { label: "Od Premium", name: "basic_od_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Od Net Premium", name: "od_net_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "TP Premium", name: "basic_tp_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "TP Net Premium", name: "tp_net_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "GST", name: "gst", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Gross Premium", name: "gross_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Net Premium", name: "net_premium", type: (policyDetailsData?.is_verified?"display":"text"), "required":true },
                                    { label: "GST Rate", name: "gst_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Owner Driver", name: "pa_owner_driver_cover_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Roadside Assistance", name: "roadside_assistance_cover_premium", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Stamp Duty", name: "stamp_duty", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Cess Rate", name: "cess_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Broker Rate", name: "total_broker_payin_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Tp Broker Rate", name: "broker_payin_rate_for_tp", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Owner Driver Per", name: "broker_payin_rate_for_od", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Reward Rate", name: "broker_reward_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Reward Tp Rate", name: "broker_reward_rate_for_tp", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Reward Rate On", name: "broker_reward_rate_based_on", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Ref Rate", name: "refferal_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Ref Tp Rate", name: "refferal_rate_for_tp", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Pos Misp Rate", name: "posp_payout_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Tp Pos Misp Rate", name: "posp_payout_rate_for_tp", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Csc Rate", name: "csc_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Csc Rate", name: "expected_payout_rate", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Csc Rate", name: "expected_payout_rate_on_tp", type: (policyDetailsData?.is_verified?"display":"text") },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Policy Details",
                                grid: 3,
                                fields: [
                                    { label: "Insurer", name: "insurer_name", type: (policyDetailsData?.is_verified?"display":"text"), "required":true },
                                    { label: "Insurer Branch Code", name: "issuing_branch_id", type: (policyDetailsData?.is_verified?"display":"text"),  },
                                    { label: "Product Name", name: "product_name", type: (policyDetailsData?.is_verified?"display":"text"),  },
                                    { label: "Policy Type", name: "policy_type", type: (policyDetailsData?.is_verified?"display":"text"), 
                                    validation: { pattern: "/\\bSAOD$|\\bThird Party$|\\bComprehensive$/" }, } ,
                                    { label: "Policy No", name: "policy_number", type: (policyDetailsData?.is_verified?"display":"text"), "required":true },
                                    { label: "Policy Term", name: "policy_term", type: policyDetailsData?.is_verified?"display":"text" , "required":true },
                                    { label: "OD Term", name: "od_term", type: policyDetailsData?.is_verified ?"display":"text", "required":true },
                                    { label: "TP Term", name: "tp_term", type: policyDetailsData?.is_verified ?"display":"text", "required":true },
                                    { label: "Start Date", name: "policy_start_date", type: (policyDetailsData?.is_verified?"display":"date"), "required":true },
                                    { label: "Expiry Date", name: "policy_end_date", type: (policyDetailsData?.is_verified?"display":"date"), "required":true },
                                    { label: "Policy Issue Date", name: "policy_issue_date", type: (policyDetailsData?.is_verified?"display":"date") , "required":true},
                                    { label: "Policy Received Date", name: "policy_received_date", type: (policyDetailsData?.is_verified?"display":"date") },
                                    { label: "Country", name: "country", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Broker Branch Code", name: "broker_branch_id", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Broker Branch", name: "broker_branch_name", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Pol Recieved Format", name: "policy_received_format", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Broker Biz Type", name: "broker_business_type", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Insurer Biz Type", name: "insurer_business_type", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Cover Note No", name: "covernote_number", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Policy Status", name: "policy_status", type: (policyDetailsData?.is_verified?"display":"text") },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Payment Details",
                                grid: 3,
                                fields: [
                                    { label: "Mode", name: "payment_mode", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Transaction Date", name: "transaction_date", type: (policyDetailsData?.is_verified?"display":"date") },
                                    { label: "Receipt No", name: "receipt_number", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Premium Receipt No", name: "premium_receipt_number", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Premium Receipt Date", name: "premium_receipt_date", type: (policyDetailsData?.is_verified?"display":"date") },
                                    { label: "Bus Prop Date", name: "bus_prop_date", type: (policyDetailsData?.is_verified?"display":"date") },
                                    { label: "Pay At", name: "pay_at", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Transaction No", name: "transaction_number", type: (policyDetailsData?.is_verified?"display":"text") },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Bussiness Details",
                                grid: 3,
                                fields: [
                                    { label: "Ref Pos Misp", name: "channel", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Rm Code", name: "rm_id", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Solicit Code", name: "solicit_id", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Csc Code", name: "csc_code", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Tc Code", name: "tc_code", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Ref Pos Misp Code", name: "ref_pos_misp_code", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Campaign Name", name: "policy_source_campaigning_name", type: (policyDetailsData?.is_verified?"display":"text") },
                                    { label: "Remarks", name: "policy_remarks", type: (policyDetailsData?.is_verified?"display":"text") },
                                ],
                            },
                        ]}
                    ></FormWidget>
                {policyDetailsData?.is_verified?( <PMGrid container={true} xs={12}>
                    <DetailWidget direction="row"
                            justifyContent="center"
                            xs={12}
                            header="Pdf"
                            fields={[
                                { label: "File", value: 'https://test-pdfs.demo.permute.in/'+policyDetailsData?.pdf_file_name,},
                                { label: "Status", value: policyDetailsData?.pdf?.status, },
                            ]}>

                    </DetailWidget>
                    <DetailWidget direction="row"
                            justifyContent="center"
                            xs={12}
                            header="Request"
                            fields={[
                                { label: "DOC", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.request?.doc),},
                                { label: "File", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.request?.file),},
                                { label: "Requested At", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.request?.request_time),},
                                { label: "Client Policy Identifier", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.request?.client_policy_identifier),},
                            ]}>

                    </DetailWidget>

                <PMGrid container={true} xs={12}>
                    <DetailWidget direction="row"
                            justifyContent="center"
                            xs={12}
                            header="Response"
                            fields={[
                                { label: "Flags", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.flags),},
                                { label: "Client Policy Identifier", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.client_policy_identifier),},
                                { label: "Form ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.form_id),},
                                { label: "Form No", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.form_num),},
                                { label: "Is Processed", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.is_processed),},
                                { label: "Timestamp", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.timestamp),},
                                { label: "Case ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.case_id),},
                                { label: "Transaction ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.transaction_id),},
                                { label: "Is Supported", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.is_supported),},
                                { label: "Vendor ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.vendor_id),},
                            ]}>

                    </DetailWidget>
                    <DetailWidget direction="column"
                            justifyContent="center"
                            xs={12}
                            header = "Extraction"
                            fields={[
                                { label: "Prodcut ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.product_id),},
                                { label: "Cust Name", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.cust_name),},
                                { label: "Insurer ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.insurer_id),},
                                { label: "Policy No", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.policy_number),},
                                { label: "Plan Type ID", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.plan_type_id),},
                                { label: "Carrier", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.carrier),},
                                { label: "Issuance Date", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.issuance_date),},
                                { label: "Policy Start Date", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.policy_start_date),},
                                { label: "Policy End Date", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.policy_end_date),},
                                { label: "Policy Term", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.policy_term),},
                                { label: "Payment Term", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.pay_term),},
                                { label: "OD Term", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.od_term),},
                                { label: "TP Term", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.tp_term),},
                                { label: "Previous Policy No", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.previous_policy_number),},
                                { label: "ncb_percent", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.ncb_percent),},
                                { label: "Registration Date", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.registration_date),},
                                { label: "Manufacturing Date", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.manufacturing_date),},
                                { label: "Reg Num", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.reg_num),},
                                { label: "VehicleClass", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.VehicleClass),},
                                { label: "VehicleSubClass", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.VehicleSubClass),},
                                { label: "Cubic Capacity Value", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.cubic_capacity_value),},
                                { label: "Fuel Type", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.fuel_type),},
                                { label: "Vehicle Weight Value", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.vehicle_weight_value),},
                                { label: "No of sheets", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.no_of_sheets),},
                                { label: "No of Wheels", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.no_of_wheels),},
                                { label: "OD Premium", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.od_premium),},
                                { label: "TP Premium", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.tp_premium),},
                                { label: "Net Premium", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.net_premium),},
                                { label: "Premium", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.premium),},
                                { label: "PA Cover", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.pa_cover),},
                                { label: "Sum Insured", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.sum_insured),},
                                { label: "RTO Num", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.rto_num),},
                                { label: "RTO City", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.rto_city),},
                                { label: "RTO State", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.rto_state),},
                                { label: "Search Text Make", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.search_text_make),},
                                { label: "Search Text Model", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.search_text_model),},
                                { label: "Search Text Variant", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.search_text_variant),},
                                { label: "Intermediary Name", value: JSON.stringify(policyDetailsData?.pdf?.extracted_data?.response?.extraction?.intermediary_name),},
                            ]}>

                    </DetailWidget>
                </PMGrid>
                </PMGrid>):""}
               
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default EditPolicy;
