// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { usePdfCreateMutation } from "../store/pdfCreateSlice";
import { jsonToFormData } from "../helperFn/formData";
import { default as pdfCreateFromatter } from "../transformations/pdfCreateFromatter";
function UploadPdf() {
    const pdfCreateMutation = usePdfCreateMutation();
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const pdfCreateNav = (data) => {
        history("/policies/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Created", { variant: "success" });
    };

    const pdfCreate = async (data) => {
        var apiData = pdfCreateFromatter(data);

        if (apiData) {
            await pdfCreateMutation[0](jsonToFormData({ file: apiData["file"], status: apiData["status"] })).unwrap();
        }
    };

    const pdfCreateSucess = (data) => {
        showSuccessAlert(data);
        pdfCreateNav(data);
    };
    useEffect(() => {
        if (pdfCreateMutation[1].isSuccess) {
            pdfCreateSucess(pdfCreateMutation[1]);
        }
        if (pdfCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(pdfCreateMutation[1]), { variant: "error" });
        }
    }, [pdfCreateMutation]);

    const pdfCreateApi = (data) => {
        pdfCreate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Upload Policy" }}>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        isLoading={pdfCreateMutation[1].isLoading}
                        header="Upload Policy PDF for extraction"
                        submitButtonLabel="Extract"
                        Onsubmit={pdfCreateApi}
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [{ label: "Upload PDF File", name: "file", type: "file", required: true }],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default UploadPdf;
