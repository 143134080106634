// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import {} from "../lib";
import { MenuWidget } from "./MenuWidget";

function ViewHistory() {
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <></>
            </MenuWidget>
        </div>
    );
}
export default ViewHistory;
