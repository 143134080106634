import { useNavigate } from "react-router-dom";
import { PMMenu } from "../lib";

import { ReactNode } from "react";

export interface MenuWidgetProps {
    data?: Array<Object>;
    datamapper?: Object;
    onClick?: Function;
    children?: ReactNode;
}
export function MenuWidget(props: MenuWidgetProps) {
    const history = useNavigate();

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    const createComponent = (data, index) => {
        return (
            <PMMenu
                open={true}
                title={
                    data
                        ? props.datamapper && props.datamapper["title"]
                            ? data[props.datamapper["title"]]
                            : data?.title
                        : ""
                }
                logo="https://gromoinsure.in/static/media/BRAND_LOGO.269ef3f11bd84ac60998.webp"
                onClick={MenuHomeCLick}
                menuItems={[
                    { type: "Link", text: "Home", url: "/", icon: { icon: "home", text_color: "info", size: "large" } },
                    { type: "Link", text: "Dashboard", url: "/dashboard", icon: { icon: "dashboard", text_color: "info", size: "large" } },
                    { type: "Divider" },
                    {
                        type: "Link",
                        text: "Upload PDF",
                        url: "/upload-pdf",
                        icon: { icon: "upload_file", text_color: "info", size: "large" },
                    },
                    {
                        type: "Link",
                        text: "View Policies",
                        url: "/policies/",
                        icon: { icon: "newspaper", text_color: "info", size: "large" },
                    },
                    {
                        type: "Nested",
                        text: "Reports",
                        icon: { icon: "assessment", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Product Report",
                                url: "/product-reports",
                                icon: { icon: "category", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Extraction Report",
                                url: "/reports",
                                icon: { icon: "picture_as_pdf", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                ]}
            >
                {props.children}
            </PMMenu>
        );
    };

    return (
        <>
            {Array.isArray(props.data)
                ? props.data?.map((ele, index) => {
                      return createComponent(ele, index);
                  })
                : props.data
                ? createComponent(props.data, "1")
                : createComponent([], "1")}
        </>
    );
}
export default MenuWidget;
