import { configureStore } from "@reduxjs/toolkit";
import appStoreReducer from "./appSlice";

import { loginApiSlice } from "./loginApiSlice";

import { pdfCreateSlice } from "./pdfCreateSlice";

import { policyListSlice } from "./policyListSlice";

import { policyDetailsSlice } from "./policyDetailsSlice";

import { policyUpdateSlice } from "./policyUpdateSlice";

import { totalPremiumTypeSlice } from "./totalPremiumTypeSlice";

import { countPremiumTypeSlice } from "./countPremiumTypeSlice";

import { countAllProcessedSlice } from "./countAllProcessedSlice";

import { clientListSlice } from "./clientListSlice";

import { policyListVerifiedSlice } from "./policyListVerifiedSlice";

import { policyListUnverifiedSlice } from "./policyListUnverifiedSlice";

import { countAllProcessedDateSlice } from "./countAllProcessedDateSlice";

import { countSuccessProcessedSlice } from "./countSuccessProcessedSlice";

import { countSuccessDateSlice } from "./countSuccessDateSlice";

import { policyListDateSlice } from "./policyListDateSlice";

export default configureStore({
    reducer: {
        appStore: appStoreReducer,
        [loginApiSlice.reducerPath]: loginApiSlice.reducer,
        [pdfCreateSlice.reducerPath]: pdfCreateSlice.reducer,
        [policyListSlice.reducerPath]: policyListSlice.reducer,
        [policyDetailsSlice.reducerPath]: policyDetailsSlice.reducer,
        [policyUpdateSlice.reducerPath]: policyUpdateSlice.reducer,
        [totalPremiumTypeSlice.reducerPath]: totalPremiumTypeSlice.reducer,
        [countPremiumTypeSlice.reducerPath]: countPremiumTypeSlice.reducer,
        [countAllProcessedSlice.reducerPath]: countAllProcessedSlice.reducer,
        [clientListSlice.reducerPath]: clientListSlice.reducer,
        [policyListVerifiedSlice.reducerPath]: policyListVerifiedSlice.reducer,
        [policyListUnverifiedSlice.reducerPath]: policyListUnverifiedSlice.reducer,
        [countAllProcessedDateSlice.reducerPath]: countAllProcessedDateSlice.reducer,
        [countSuccessProcessedSlice.reducerPath]: countSuccessProcessedSlice.reducer,
        [countSuccessDateSlice.reducerPath]: countSuccessDateSlice.reducer,
        [policyListDateSlice.reducerPath]: policyListDateSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginApiSlice.middleware,
            pdfCreateSlice.middleware,
            policyListSlice.middleware,
            policyDetailsSlice.middleware,
            policyUpdateSlice.middleware,
            totalPremiumTypeSlice.middleware,
            countPremiumTypeSlice.middleware,
            countAllProcessedSlice.middleware,
            clientListSlice.middleware,
            policyListVerifiedSlice.middleware,
            policyListUnverifiedSlice.middleware,
            countAllProcessedDateSlice.middleware,
            countSuccessProcessedSlice.middleware,
            countSuccessDateSlice.middleware,
            policyListDateSlice.middleware
        ),
});
