const totalPremiumTypeFormatter = (data, datakey) => {
  const returnData = {};
  returnData[datakey] = {
    CV: { sum: 0, count: 0 },
    TW: { sum: 0, count: 0 },
    FW: { sum: 0, count: 0 },
  };
  const dataSet = data.data || data;
  dataSet.forEach((element) => {
    if (
      ["pcv", "gcv", "misc"].some((v) =>
        element["policy_type"].toLowerCase().includes(v)
      )
    ) {
      returnData[datakey]["CV"]["sum"] =
        (returnData[datakey]["CV"]["sum"] || 0) + (element["sum"] || 0);
      returnData[datakey]["CV"]["count"] =
        (returnData[datakey]["CV"]["count"] || 0) + (element["count"] || 0);
    } else if (
      ["two wheeler"].some((v) =>
        element["policy_type"].toLowerCase().includes(v)
      )
    ) {
      returnData[datakey]["TW"]["sum"] =
        (returnData[datakey]["TW"]["sum"] || 0) + (element["sum"] || 0);
      returnData[datakey]["TW"]["count"] =
        (returnData[datakey]["TW"]["count"] || 0) + (element["count"] || 0);
    } else {
      returnData[datakey]["FW"]["sum"] =
        (returnData[datakey]["FW"]["sum"] || 0) + (element["sum"] || 0);
      returnData[datakey]["FW"]["count"] =
        (returnData[datakey]["FW"]["count"] || 0) + (element["count"] || 0);
    }
  });
  Object.keys(returnData[datakey]).forEach((ele) => {
    returnData[datakey][ele][
      "count"
    ] = `${returnData[datakey][ele]["count"]} Policies`;
    returnData[datakey][ele]["sum"] = `₹ ${convertToString(
      parseInt(returnData[datakey][ele]["sum"])
    )} Premium`;
  });

  return returnData;
};

const convertToString = (val) => {
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + " Lac";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(0) + " k";
  }
  return val;
};

export default totalPremiumTypeFormatter;
