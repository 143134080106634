const policyDetailVerifiedFormatter = (data, datakey) => {
  console.log(data)
    const returnData = {};
    returnData[datakey] = JSON.parse(JSON.stringify(data));
    returnData[datakey]['mandatory'] = [
        'product_name',
        'customer_name',
        'insurer_name',
        'policy_number',
        'policy_type',
        'cubic_capicity',
        'policy_term',
        'gross_premium',
        'rto_code',
        'rto_city',
        'rto_state',
        'vehicle_make',
        'vehicle_model',
        'date_of_manufacture',
        'policy_issue_date',
        'policy_start_date',
        'policy_end_date',
        'net_premium',
        'gross_premium',
        'sum_insured',
    ];
    returnData[datakey]['do_verification'] = [];
    if (!data['product_name']) {
        returnData[datakey]['do_verification'].push('product_name');
    }
    if (!data['customer_name']) {
        returnData[datakey]['do_verification'].push('customer_name');
    }
    if (!data['insurer_name']) {
        returnData[datakey]['do_verification'].push('insurer_name');
    }

    if (!data['policy_number']) {
        returnData[datakey]['do_verification'].push('policy_number');
    }
    if (!data['policy_type']) {
        returnData[datakey]['do_verification'].push('policy_type');
    }
    if (!data['cubic_capicity']) {
        returnData[datakey]['do_verification'].push('cubic_capicity');
    }
    if (!data['policy_term']) {
        returnData[datakey]['do_verification'].push('policy_term');
    }
    if (!data['gross_premium']) {
        returnData[datakey]['do_verification'].push('gross_premium');
    }
    if (!data['rto_code']) {
        returnData[datakey]['do_verification'].push('rto_code');
    }
    if (!data['rto_city']) {
        returnData[datakey]['do_verification'].push('rto_city');
    }
    if (!data['rto_state']) {
        returnData[datakey]['do_verification'].push('rto_state');
    }
    if (!data['vehicle_make']) {
        returnData[datakey]['do_verification'].push('vehicle_make');
    }
    if (!data['vehicle_model']) {
        returnData[datakey]['do_verification'].push('vehicle_model');
    }
    if (!['SAOD', 'Comprehensive', 'Third Party'].includes(data['policy_type'])) {
        returnData[datakey]['do_verification'].push('policy_type');
    }
    if ([2, 3, 4].includes(data['number_of_wheels'])) {
        returnData[datakey]['do_verification'].push('number_of_wheels');
    }
    if (parseInt(data['no_claim_bonus']) > 50) {
        returnData[datakey]['do_verification'].push('no_claim_bonus');
    }
    if (!(data['tp_term'] <= 5)) {
        returnData[datakey]['do_verification'].push('tp_term');
    }
    if (
        (data['basic_tp_premium'] == 0 && data['policy_type'] == 'SAOD') ||
        (data['basic_tp_premium'] > 0 &&
            data['policy_type'] == 'Comprehensive' &&
            [data['net_premium'], data['net_premium'] - 1, data['net_premium'] + 1].includes(
                data['basic_tp_premium'] + (data['basic_own_damage_premium'] || 0)
            ))
    ) {
        returnData[datakey]['do_verification'].push('basic_tp_premium');
        returnData[datakey]['do_verification'].push('net_premium');
    }
    if (
        (data['basic_own_damage_premium'] == 0 && data['policy_type'] == 'Third Party') ||
        (data['basic_own_damage_premium'] > 0 &&
            data['policy_type'] == 'Comprehensive' &&
            [data['net_premium'], data['net_premium'] - 1, data['net_premium'] + 1].includes(
                data['basic_tp_premium'] + (data['basic_own_damage_premium'] || 0)
            ))
    ) {
        returnData[datakey]['do_verification'].push('basic_own_damage_premium');
        returnData[datakey]['do_verification'].push('net_premium');
    }
    if (new Date(data['date_of_registration']) < new Date(data['date_of_manufacture'])) {
        returnData[datakey]['do_verification'].push('date_of_registration');
    }
    if (
        !data['date_of_manufacture'] ||
        new Date(data['date_of_registration']) > new Date(data['date_of_manufacture'])
    ) {
        returnData[datakey]['do_verification'].push('date_of_manufacture');
    }
    if (
        !data['policy_issue_date'] ||
        new Date(data['policy_issue_date']) > new Date(data['policy_start_date'])
    ) {
        returnData[datakey]['do_verification'].push('policy_issue_date');
    }
    if (
        !data['policy_start_date'] ||
        new Date(data['policy_issue_date']) > new Date(data['policy_start_date'])
    ) {
        returnData[datakey]['do_verification'].push('policy_start_date');
    }
    if (!data['policy_end_date']) {
        returnData[datakey]['do_verification'].push('policy_start_date');
    }

    if (
        ['SAOD', 'Comprehensive', 'Package'].includes(data['policy_type']) &&
        data['sum_insured'] == 0
    ) {
        returnData[datakey]['do_verification'].push('sum_insured');
    }

    return returnData;
};

export default policyDetailVerifiedFormatter;
