const policyListListVerifiedFormatter = (data, datakey) => {
  const returnData = {};
  returnData[datakey] = JSON.parse(JSON.stringify(data));
  returnData[datakey]["verified"] = []
  returnData[datakey]["unverified"] = []
  returnData[datakey]["error"] = []
  console.log(data,"=====================data")
  if (returnData[datakey]?.items){
    returnData[datakey]["items"]?.forEach(element => {
      console.log(element?.extracted_data?.client,"======================client")
      element["client"] = element?.extracted_data?.client?.api_key
      element["client_policy_identifier"] = element?.extracted_data?.request?.client_policy_identifier
      element["transaction_amount"] = element?.extracted_data?.transactionlog?.transaction_amount
      element["requested_at"] = element?.extracted_data?.request?.request_time
      element["created_at"] = element?.policy?.created_at
      element["customer_name"] = element?.policy?.customer_name
      element["policy_number"] = element?.policy?.policy_number
      element["insurer_name"] = element?.policy?.insurer_name
      if (element["status"]=="SUCCESS"){
        element["id"] = element?.policy?.id
        if (element?.policy?.is_verified){
          returnData[datakey]["verified"].push(element)
        }
        else{
          returnData[datakey]["unverified"].push(element)
        }
      }
      else{
          returnData[datakey]["error"].push(element)
      }
      
    });
  }
  else{
    returnData[datakey] = {"items":[]}
  }
  return returnData;
};

export default policyListListVerifiedFormatter;
